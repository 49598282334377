import { getCurrentUser } from "aws-amplify/auth/server";
import * as React from "react";
import FastQuoteForm from "../components/FastQuoteForm";
import Heading from "../components/Heading";
import RecentTransactions from "../components/RecentTransactions/RecentTransactions";
import Balances from "../components/balances/Balances";
import PageLoader from "../components/page-loader";
import Page from "../components/page/Page";
import WelcomeScreen from "../components/welcome/WelcomeScreen";
import Utils from "../core/Utils";
import { useApp } from "../ctx/AppProvider";
import { GetBalancesDocument, GetTradesDocument } from "../graphql/gql-types";
import useQuery from "../hooks/useQuery";
import { serverSideProps, withSSRContext } from "../lib/withSSRContext";

import type { GetServerSideProps } from "next";
import type {
  Balance,
  Client,
  GetBalancesQuery,
  GetBalancesQueryVariables,
  GetTradesQuery,
  GetTradesQueryVariables,
  Trade,
} from "../graphql/gql-types";

const Dashboard = (): JSX.Element => {
  const [activeClient] = useApp<Client>((store) => store.activeClient);

  const { data, isLoading: transactionsLoading } = useQuery<
    GetTradesQuery["getTrades"],
    GetTradesQuery,
    GetTradesQueryVariables
  >(activeClient?.cli_reference ? GetTradesDocument : null, {
    client_ref: activeClient?.cli_reference,
    limit: 20,
    currency: null,
    filter: null,
  });

  const {
    data: balances,
    isLoading: balancesLoading,
    error: balanceFetchError,
  } = useQuery<Balance[], GetBalancesQuery, GetBalancesQueryVariables>(
    activeClient?.cli_reference ? GetBalancesDocument : null,
    {
      client_ref: activeClient?.cli_reference,
    }
  );

  if (!activeClient || transactionsLoading) {
    return <PageLoader />;
  }

  const shouldDisplayBalances =
    !balancesLoading && !balanceFetchError && Utils.isNotEmpty(balances);

  return (
    <Page>
      {data?.trades?.length || shouldDisplayBalances ? (
        <>
          <div className="flex flex-wrap py-2 sm:px-8 sm:py-4 md:py-8">
            {activeClient?.ctc_first_name && (
              <Heading
                title={`Welcome ${activeClient.ctc_first_name}`}
                subTitle={"Where would you like to start?"}
              />
            )}
            {activeClient?.cli_reference && (
              <Heading
                title={"Account Number"}
                subTitle={`${activeClient.cli_reference}`}
                align="right"
                as="h3"
              />
            )}
          </div>

          {shouldDisplayBalances && <Balances items={balances} />}

          <div className="flex flex-col lg:flex-row">
            <div className="bg-white lg:order-2 px-2 md:px-6 pt-8 flex-grow">
              <FastQuoteForm />
            </div>

            <div className="bg-white lg:order-1 px-2 md:px-6 pt-8 flex-grow lg:w-2/3">
              <RecentTransactions
                data={data?.trades as Trade[]}
                hasMoreTrades={data?.totalCount > data?.trades.length}
              />
            </div>
          </div>
        </>
      ) : (
        <div>
          <WelcomeScreen currency={balances[0]?.currency} />
        </div>
      )}
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  const runWithAmplifyServerContext = withSSRContext(req);

  try {
    const currentUser = await runWithAmplifyServerContext({
      nextServerContext: { request: req, response: res },
      operation: getCurrentUser
    });

    return serverSideProps(req, {
      props: {
        redirect: "/",
        authenticated: true,
        user: {
          email: currentUser.signInDetails.loginId
        },
      },
    });
  } catch (err) {
    return serverSideProps(req, {
      props: {
        authenticated: false,
        user: null,
        redirect: "/login",
      },
    });
  }
};

export default Dashboard;
